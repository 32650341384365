import React from 'react';
import Layout from './src/components/layout/layout';
import "bootstrap/dist/css/bootstrap.min.css";
import { GatsbyBrowser } from 'gatsby';
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
// Logs when the client route changes

// Wraps every page in a component
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
    element,
  }) => {
    return (
      <>
        {element}
      </>
    )
  }